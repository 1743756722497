
import { Button, PasswordInput } from '@/ui';
import { defineComponent } from 'vue';
import { Snackbar, FixedHeaderTop, PageHeader, Popup } from '@/components/';
import { UserController, ContentController } from '../../controller';
import { CookieService } from '@/utils';

export default defineComponent({
    components: { Snackbar, FixedHeaderTop, Button, PasswordInput, PageHeader, Popup },
    data() {
        return {
            showRegisterForm: false,
            showLoginForm: false,
            showPWForgot: false,
            responseState: '',
            icons: {
                logo: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            form: {
                firstName: "",
                lastName: "",
                username: "",
                password: "",
                newsletter: true,
                privacy: false,
                terms: false,
                registrycode: "",
                abocode: "",
            },
            iHaveACode: false,
            storagePid: '',
            passwordrepeat: "",
            validateForm: false,
            waitForResponse: false,
            showUserCreated: false,
            registrationCodePreselected: false,
            loader: "<?xml version=\"1.0\" encoding=\"utf-8\"?><svg version=\"1.1\" id=\"Ebene_1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" viewBox=\"0 0 38 38\" style=\"enable-background:new 0 0 38 38;\" xml:space=\"preserve\"><style type=\"text/css\">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform=\"translate(1 1)\"><circle class=\"st0\" cx=\"18\" cy=\"18\" r=\"18\"/><path class=\"st1\" d=\"M36,18c0-9.9-8.1-18-18-18\"><animateTransform  accumulate=\"none\" additive=\"replace\" attributeName=\"transform\" calcMode=\"linear\" dur=\"1s\" fill=\"remove\" from=\"0 18 18\" repeatCount=\"indefinite\" restart=\"always\" to=\"360 18 18\" type=\"rotate\"></animateTransform></path></g></g></svg>",
            webviewMode: false,
            contentUri: '',
            showPopup: false
        };
    },
    created(){
        const cookie = CookieService.readCookie('_alprelax_show_licence');
        this.webviewMode = cookie == 'true';     
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        if (this.$route.params.id) {
            this.registrationCodePreselected = true
            this.form.abocode = this.$route.params.id as string
        }
        if (this.$route.params.code) {
            this.registrationCodePreselected = true
            this.form.registrycode = this.$route.params.code as string
        }
            //clear url to avoid wrong add to home screen
        this.clearUrl();
    },
    methods: {
        clearUrl() {
            //console.log(window.location);
            let url = window.location.href;
                //should neve be because of removing trailing slash in .htaccess
            /*if (url.slice(-1) == '/') {
                url = url.slice(0,-1);
            }*/
                //to remove only the param from url
            //url = url.slice(0,url.lastIndexOf('/'));
            url = url.replace(window.location.pathname, '');
            window.history.replaceState(null, '', url);
            //console.log('window.location modified', window.location.pathname, url);
        },
        getErrorClass(fieldType: any, input: any) {
            if (this.validateForm) {
                if (input == "") {
                    return "required";
                }
                else if (fieldType == "email") {
                    var re = /\S+@\S+\.\S+/;
                    if (!re.test(input)) {
                        return "wrongMail";
                    }
                }
                else if (fieldType == "password") {
                    if (input.length < 8) {
                        return "toShort";
                    }
                }
                else if (fieldType == "passwordRepeat") {
                    if (this.passwordrepeat != this.form.password) {
                        return "dontMatch";
                    }
                }
                else if (fieldType == "string") {
                    if (input.length < 3) {
                        return "toShort";
                    }
                }
            }
        },
        onValidateForm() {
            //NOL: Quick&Dirty Validation
            //check valid mail
            var re = /\S+@\S+\.\S+/;
            if (!re.test(this.form.username)) {
                return false;
            }
            //check other fields
            if (this.form.firstName.length >= 3 && this.form.firstName.length >= 3 && this.form.lastName.length >= 3 && this.form.password.length >= 8 && this.form.password == this.passwordrepeat && this.form.privacy == true) {
                return true;
            }
            return false;
        },
        handleSubmitForm() {
            this.validateForm = true;
            if (this.onValidateForm()) {
                this.handleCreateUser();
            }
        },
        handleCreateUser() {
            this.createUser()
            this.validateForm = false;
        },
        async createUser(){
            this.waitForResponse = true;
            let res = await UserController.createUser(this.form);
         
            if(res.data.success){
                this.showUserCreated = true;
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
            this.waitForResponse = false;
        },
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error
        
            setTimeout(() => {
                this.snackbar.show = false
           }, 5000)
        },
        openPrivacyPopup() {
            this.contentUri = ContentController.PRIVACY_URI as string
            this.showPopup = true
        },
        openTermsPopup() {
            this.contentUri = ContentController.TERMS_URI as string
            this.showPopup = true
        }        
    }
})
